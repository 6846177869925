<div class="notifications-widget card">
  <div class="card-header d-flex justify-content-between align-items-center">
    <h5 class="mb-0">Notifications</h5>
    <div class="btn-group">
      <button class="btn btn-sm" 
            [class.btn-primary]="!(showUnreadOnly())"
            [class.btn-outline-primary]="showUnreadOnly()"
            (click)="setUnreadFilter(false)">
        All
      </button>
      <button class="btn btn-sm" 
              [class.btn-primary]="showUnreadOnly()"
              [class.btn-outline-primary]="!(showUnreadOnly())"
              (click)="setUnreadFilter(true)">
        Unread ({{notificationsService.unreadCount()}})
      </button>
    </div>
  </div>
  
  <div class="card-body">
      <div class="mb-3 text-right d-flex justify-content-end align-items-center gap-2">
        @if (filteredNotifications().length > 0) {
            <button class="btn btn-light btn-sm" (click)="markAllAsRead()">
            <fa-icon [icon]="faCheckDouble" class="me-2 text-primary"></fa-icon>
            Mark all as read
            </button>
            <button class="btn btn-light btn-sm" 
                    (click)="deleteAllNotifications()"
                    [ngbTooltip]="'Delete all notifications'"
                    placement="left">
            <fa-icon [icon]="faTrash" class="text-primary"></fa-icon>
            </button>
        }
        <button class="btn btn-light btn-sm" 
            (click)="navToSettings()" 
            [ngbTooltip]="'Manage notification settings'"
            placement="left">
            <fa-icon [icon]="faGear" class="me-2 text-primary"></fa-icon>
        </button>
      </div>



    <div #notificationsList 
         class="notifications-list"
         (scroll)="onScroll($event)">
      @for (notification of filteredNotifications(); track notification.userNotificationId) {
        <div class="notification-item"
             [class.read]="notification.markedAsRead">
          <div class="d-flex justify-content-between align-items-start">
            <div class="notification-content">
              <p class="mb-1">{{ notification.message }}</p>
              <small class="text-muted">
                {{ notification.notifiedDate| utcToLocal | date:'medium' }} 
              </small>
            </div>
            <div class="notification-actions">
              @if (!notification.markedAsRead) {
                <span class="unread-dot" (click)="markAsRead(notification)"></span>
              }
              <div class="dropdown" ngbDropdown placement="bottom-right">
                <button class="btn btn-link p-0 menu-toggle" 
                        ngbDropdownToggle>
                  <i class="menu-triangle"></i>
                </button>
                <div class="dropdown-menu" ngbDropdownMenu>
                  @if (!notification.markedAsRead) {
                    <button ngbDropdownItem (click)="markAsRead(notification)">
                      Mark as read
                    </button>
                  }
                  <button ngbDropdownItem (click)="deleteNotification(notification)">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          @if (notification.link) {
            <a [href]="notification.link" 
               class="notification-link">
              View details
            </a>
          }
        </div>
      } @empty {
        <div class="text-center text-muted py-3">
          No notifications to display
        </div>
      }
    </div>

    @if (hasMoreNotifications() && !navService.isMobile()) {
      <div class="text-center mt-3">
        <button class="btn btn-outline-primary btn-sm" (click)="showMore()">
          Show previous notifications
        </button>
      </div>
    }
  </div>
</div>