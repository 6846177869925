import { Component, OnInit, OnDestroy } from '@angular/core';
import { PushNotificationService } from './push-notification.service';
import { pcgSettings } from 'app/shared/generated/pcg-settings';

@Component({
    selector: 'pcg-push-notification',
    templateUrl: './push-notification.component.html',
    styleUrls: ['./push-notification.component.scss'],
    standalone: false
})
export class PushNotificationComponent implements OnInit, OnDestroy {
	private eventSource: EventSource;
	isLocalHost = pcgSettings.mode === 'Development';
	showNotification = false;
	scheduledInstall = false;
	navSpacerVisible = false;

	/** Reload the page */
	hardReload() {
		window.location.reload();
		return false;
	}

	ngOnInit() {
		if (!this.isLocalHost) {
			this.connectToSSE();
		}	
	}

	ngOnDestroy() {
		if (this.eventSource) {
			this.eventSource.close();
		}
	}
	/** Connect to Server Sent Event and listen for events */
	private connectToSSE() {
        this.eventSource = new EventSource(`/api/install-events`);

		this.eventSource.addEventListener('install', () => {
			this.showNotification = true;
			localStorage.removeItem('update-timer');
			PushNotificationService.checkForSystemUpdate();
			this.scheduledInstall = false;
			this.navSpacerVisible = PushNotificationService.checkForNavSpace();
		});

		this.eventSource.addEventListener('install-scheduled', (event: MessageEvent) => {
			const scheduledTime = new Date(JSON.parse(event.data));
			localStorage.setItem('update-timer', JSON.stringify(scheduledTime));
			this.scheduledInstall = PushNotificationService.checkForSystemUpdate() != null;
		});

	}
}
