import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { faFingerprint, faUsbDrive } from '@fortawesome/pro-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FingerprintService } from 'app/core/security/multi-factor-auth/fingerprint.service';
import { WebAuthnService } from 'app/core/security/multi-factor-auth/web-authn.service';
import { SecurityService } from 'app/core/security/security.service';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';
import { LoginVm } from 'app/shared/generated/Models/LoginVm';
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';
import { FidoService } from 'app/shared/services/fido.service';
import { GlobalService } from 'app/shared/services/global.service';
import { MultiFactorAuthModalComponent } from './multi-factor-auth-modal/multi-factor-auth-modal.component';
import { GlobalVariablesService } from 'app/services/global-variables.service';
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';

@Component({
    selector: 'pcg-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnDestroy, OnInit, AfterViewInit {
	@ViewChild('myForm', { static: true }) myForm: ElementRef;
	@ViewChild('footerEl', { static: true }) footerEl: ElementRef;

	loginForm = LoginVm.Form;
    user: UserJsVm;

	userId: number;

	showMfa = false;
	showInactiveMessage = false;
	isUsingSsoLogin = false;
	isError = false;

	errorString = "";
	ssoEmail: string = "";	
	ssoCode = "";

	faFingerprint = faFingerprint;
	faUsbDrive = faUsbDrive;
	
	constructor(
		private route: ActivatedRoute
		, private ms: SystemMessageService
		, private sec: SecurityService
		, private router: Router
		, private modalService: NgbModal
		, private webAuthn: WebAuthnService
		, private fingerprint: FingerprintService
		, private httpClient: HttpClient
		, title: Title
		, public gs: GlobalService
		, public gvs: GlobalVariablesService
	) { title.setTitle('Login'); }

	ngOnInit() {
		const user = this.sec.getUser();
		if (user) { this.loginRedirect(); }

		this.isUsingSsoLogin = this.route?.snapshot?.queryParams?.useSso === true.toString();

		document.cookie = "hasSpentSevenHalfMinutesInactive=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
		this.checkIfRedirectedFromInactivity();

		if (this.route.snapshot.queryParams.errorMessage != null) { this.ms.setSystemMessage(decodeURIComponent(this.route.snapshot.queryParams.errorMessage)); }
        if (this.route.snapshot.queryParams.userIdFromSso != null) { this.getUserObjAndLogin(); }
	}

    ngAfterViewInit() {
		const footContainer = document.querySelector('#footer .container');
		if (footContainer) { footContainer.insertBefore(this.footerEl?.nativeElement, footContainer.firstChild); }
    }

	switchSso() { this.isUsingSsoLogin = !this.isUsingSsoLogin; }

	onSubmit() {
		this.isError = false;
		this.errorString = "";

		if (this.loginForm.valid) {
			this.sec.setLocalStorage('lastAccess', new Date());
			this.httpClient.post("api/Account/Login", this.loginForm.getRawValue())
				.subscribe((msg: SystemMessage) => { 
					if (!msg.isSuccessful) { 
						this.isError = true;
						this.errorString = msg.message;
					} else { this.doLogin(msg); }
				}
			);
		} else { validateForm(this.loginForm); }
	}

	doLogin (msg: SystemMessage) {
		const val = msg.value;
		if (!val) { return; }
		
		if (val.userId) { this.userId = val.userId; }
		if (
			val.fidoMfa 
			&& val.fingerprintMfa
		) { this.showMfa = true; }
		else if (val.fidoMfa) {
			// Handle fido MFA
			this.myForm?.nativeElement?.ownerDocument?.activeElement?.blur(); // Need this hack to avoid change detection error on modal open
			this.modalService.open(MultiFactorAuthModalComponent, { animation: false }); // Open the 2FA modal
			this.doTwoFactorAuth(); // Handle 2FA
		} else if (val.fingerprintMfa) {
			// Handle fingerprint MFA
			this.doFingerprintIdentification();
		} else {
			// Successful login
			this.loginForm.reset();
			this.sec.setSecurity(val.token, val.user);
            console.log("SSE init");
			this.loginRedirect();
		}
	}

	loginRedirect() {
        const baseUrl = document.getElementsByTagName('base')[0].href;
        this.user = this.sec.getUser();
		let redirectUrl = this.route.snapshot.queryParamMap.get('redirectUrl') || sessionStorage.getItem('redirectUrl');
		if (localStorage.getItem("isNavFixed") === "true") { this.gvs.isNavFixed.set(true); }	
        if (this.user?.needToResetPin) { window.location.href = baseUrl + `/admin/users/user/edit/${this.user.id}?tab=Security&r=1`; } 
		if (LoginComponent.detectMultiRedirect(redirectUrl)) {
			this.router.navigate(['/dashboard']);
			return;
		}
		if (redirectUrl) {
			
			redirectUrl = redirectUrl.replace(/^\//, '');
			if (
				redirectUrl.startsWith('uploads/') 
				|| redirectUrl.startsWith('quartz')
			) { window.location.href = baseUrl + redirectUrl; } 
			else {
				this.router.navigateByUrl(redirectUrl);
				sessionStorage.removeItem('redirectUrl');
			}
		} else { this.router.navigate(['/dashboard']); }
	}

	// If there is more than one redirect in the url, it could cause the page to render the login component 
	// with top navigation instead of redirecting to the desired location
	static detectMultiRedirect(redirectUrl: string): boolean{
		const badUrlSubStr = 'redirectUrl';
		if(FormatHelperService.GetIsNully(redirectUrl)) { return false; }
		return redirectUrl.indexOf(badUrlSubStr) > 0;
	}

	doTwoFactorAuth() {
		if (!this.webAuthn.isFidoCompatible()) {
			this.modalService.dismissAll();
			return;
		}

		// Save user credentials and clear form
		const userName = this.loginForm.value.userName;
		const password = this.loginForm.value.password;
		this.loginForm.reset();

		// Get assertion options from the server for user
		this.webAuthn.getAssertionOptions(userName, password)
			.subscribe(async makeAssertionOptions => {
				try { 
					makeAssertionOptions = FidoService.fixAssertionOptions(makeAssertionOptions);
					let credential = await navigator.credentials.get({ publicKey: makeAssertionOptions });

					this.sec.setLocalStorage('lastAccess', new Date());
					this.webAuthn.verifyAssertion(credential, userName, password)
						.subscribe((msg: SystemMessage) => {
							this.modalService.dismissAll();
							this.doLogin(msg);
						}
					);
				} catch (e) {
					this.modalService.dismissAll();
					this.webAuthn.verifyAssertion(null, userName, password)
						.subscribe((msg: SystemMessage) => { this.doLogin(msg); });
					console.log("Error : " + e);
				} 				
			}
		);
	}

	fidoMfa() {
		// Handle fido MFA
		this.myForm?.nativeElement?.ownerDocument?.activeElement?.blur(); // Need this hack to avoid change detection error on modal open
		this.modalService.open(MultiFactorAuthModalComponent, { animation: false }); // Open the 2FA modal
		this.doTwoFactorAuth(); // Handle 2FA
	}

	fingerprintMfa() { this.doFingerprintIdentification(); }

	async doFingerprintIdentification() {
		this.isError = false;
		this.errorString = "";		
		const response = await this.fingerprint.loginFingerprintMfa(this.userId);

		// Fire the save event
		if (response !== null) {
			if (response?.isSuccessful) { 
				this.sec.setLocalStorage('lastAccess', new Date());
				this.httpClient.post("api/Account/Login?fingerprintSuccess=true", this.loginForm.getRawValue())
					.subscribe((msg: SystemMessage) => { this.doLogin(msg); });
			} else { 
				this.isError = true;
				this.errorString = "Incorrect Fingerprint.";
			}
		} 
	}

	checkIfRedirectedFromInactivity() { if (this.route.snapshot.queryParams.inactive != null) { this.showInactiveMessage = true; } }

	ssoLogin() {
		this.isError = false;
		this.errorString = "";

		this.httpClient.get(`api/Account/SsoLogin?email=${encodeURIComponent(this.ssoEmail)}`)
			.subscribe((sm: SystemMessage) => { 
				if (sm.isSuccessful) { window.location.href = sm.value; } 
				else { 
					this.isError = true;
					this.errorString = sm.message;
				}
			}
		);
	}

	getUserObjAndLogin() {
		this.isError = false;
		this.errorString = "";
        var userId = Number(this.route.snapshot.queryParams.userIdFromSso);

        this.httpClient.get(`api/Account/GetUserObjectFromId?userId=${userId}`)
			.subscribe((msg: SystemMessage) => {
				if (!msg.isSuccessful) { 
					this.isError = true;
					this.errorString = msg.message;
				} else {
					this.loginForm.reset();
					this.sec.setSecurity(msg.value.token, msg.value.user);
					localStorage.setItem("loggedInUsingSso", "true");
                    localStorage.setItem("entraIdentifierUrl", msg.value.entraIdentifierUrl);
                    localStorage.setItem("entraLoginUrl", msg.value.entraLoginUrl);
					this.loginRedirect();
				}
			}
		);
    }

	ngOnDestroy() { this.footerEl?.nativeElement.parentNode.removeChild(this.footerEl.nativeElement); }
}
