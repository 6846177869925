import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'pcg-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    standalone: false
})
export class DashboardComponent implements OnInit, OnDestroy {
    private mainContainer: HTMLElement | null = null;
    private sidenavContent: HTMLElement | null = null;

    constructor(public title: Title, private renderer: Renderer2) { 
        title.setTitle('Dashboard'); 
    }

    ngOnInit() {
        this.mainContainer = document.querySelector('.main-container');
        this.sidenavContent = document.querySelector('mat-sidenav-content');
        
        if (this.mainContainer) {
            this.renderer.addClass(this.mainContainer, 'dashboard-page');
        }
        if (this.sidenavContent) {
            this.renderer.addClass(this.sidenavContent, 'dashboard-content');
        }
    }

    ngOnDestroy() {
        if (this.mainContainer) {
            this.renderer.removeClass(this.mainContainer, 'dashboard-page');
        }
        if (this.sidenavContent) {
            this.renderer.removeClass(this.sidenavContent, 'dashboard-content');
        }
    }
}