<div *ngFor="let notif of notifs.slice().reverse(); let i = index" class="notification d-flex"
[@notificationSlide]="{ value: 'in', params: { direction: direction } }"
(click)="i === 0 ? 
        notif?.url ? 
            urlRedirect() : 
            dismissNotif() 
        : bringToFront(i)"
[ngClass]="{
    'top': notif.position?.includes('top'),
    'right': notif.position?.includes('right'),
    'bottom': notif.position?.includes('bottom'),
    'left': notif.position?.includes('left'),
    'full-width': notif.position?.includes('left') && notif.position?.includes('right'),
    'full-height': notif.position?.includes('top') && notif.position?.includes('bottom'),
    'new-tab': notif.openNewTab && notif.url && i === 0,
    'redirect': !notif.openNewTab && notif.url && i === 0,
    'grab': i != 0
}"
[ngStyle]="{
    'z-index': NOTIF_Z_INDEX + notifs.length - i,
    'opacity': i === 0 ? 1 : OLD_NOTIF_OPACITY,
    'top': !notif.position?.includes('bottom') ? i * NOTIF_OFFSET + 'px' : 'auto',
    'bottom': notif.position?.includes('bottom') ? i * NOTIF_OFFSET + 'px' : 'auto'
}">
    <!-- Notification Icon -->
    <div class="icon d-flex justify-content-center align-items-center px-3 py-4" 
    [ngStyle]="{'backgroundColor': notif.iconBgColor, 'color': notif.iconColor}">
        <fa-icon [icon]="notif.iconDefinition ? notif.iconDefinition : defaultIcon"></fa-icon>    
    </div>

    <!-- Notification Body -->
    <div class="d-flex flex-column flex-fill">
        <div class="d-flex justify-content-between pl-2"
        [ngStyle]="{'backgroundColor': notif.headerBgColor, 'color': notif.headerColor}">
            <span class="header">{{notif.header}}</span>
            <span class="time-sent ml-auto p-2">{{notif.timeSent | date: 'h:mm a'}}</span>
        </div>
        <div class="h-100 pl-2" [ngStyle]="{'backgroundColor': notif.messageBgColor, 'color': notif.messageColor}">
            <span class="message">{{notif.message}}</span>
        </div>
    </div>

    <!-- Dismiss Button -->
    <div>
        <button 
        [ngStyle]="{'backgroundColor': notif.messageBgColor, 'color': notif.messageColor}"
        (click)="i === 0 ? dismissNotif() : bringToFront(i); $event.stopPropagation();"
        class="dismiss-btn btn btn-outline-dark ml-auto">
            <fa-icon [icon]="dismissIcon"></fa-icon>
        </button>
    </div>

    <!-- Timeout Progress Bar -->
    @if(i === 0) {
        <div 
            class="timeout-bar" 
            [ngStyle]="{'width.%': barWidth}"
        ></div>
    }
</div>

