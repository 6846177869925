// In order to keep core.module.ts as small as possible,
// router functionality is kept in this file.

// This is not done with other modules because it is conventient
// to have 'ng generate component' automatically add a component to
// the appropriate module by running it in that directory.

import { RouterModule, Routes } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';

import { CiInvoicePdfComponent } from 'app/ci-repack/ci-invoices/invoice-pdf/invoice-pdf.component';
import { CiRepackOrderShortOverPrintableComponent } from 'app/ci-repack/ci-repack-order/ci-repack-order-short-over/ci-repack-order-short-over-printable/ci-repack-order-short-over-printable.component';
import { SilkierHistoryComponent } from 'app/silkier/silkier-history/silkier-history.component';
import { EditUserComponent } from '../administration/users/edit-user/edit-user.component';
import { EditUserModule } from '../administration/users/edit-user/edit-user.module';
import { KnowledgeBaseComponent } from '../help/knowledge-base/knowledge-base.component';
import { BusinessAreaEnum } from './enums/generated/BusinessAreaEnum';
import { PermissionRole } from './enums/generated/PermissionRole';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { Error404Component } from './pages/error404/error404.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { HelpDeskTicketFeedbackComponent } from './pages/help-desk-ticket-feedback/help-desk-ticket-feedback.component';
import { PublicHelpTicketComponent } from './pages/help-ticket/help-ticket.component';
import { HerosApplicationComponent } from './pages/heros-application/heros-application.component';
import { HolidaysComponent } from './pages/holidays/holidays.component';
import { HomeDeliveryComponent } from './pages/home-delivery/home-delivery.component';
import { HomeComponent } from './pages/home/home.component';
import { HomerRegisterComponent } from "./pages/homer-register/homer-register.component";
import { IcqSchedulingComponent } from "./pages/icq-scheduling/icq-scheduling.component";
import { LoginComponent } from './pages/login/login.component';
import { PfsRegisterComponent } from "./pages/pfs-register/pfs-register.component";
import { DonorRegistrationComponent } from './pages/rxconnects/donor/donor-registration.component';
import { PatientApplicationComponent } from './pages/rxconnects/patient-application/patient-application.component';
import { RepositoryRegisterComponent } from './pages/rxconnects/repository/repository-register.component';
import { TechSpecComponent } from './pages/tech-spec/tech-spec.component';
import { WebReleaseNotesComponent } from './pages/web-release-notes/web-release-notes.component';
import { AuthGuard } from './security/auth.guard';
import { SecurityService } from './security/security.service';
import { IcqFeedbackComponent } from './pages/icq-feedback/icq-feedback.component';

// This is the root of our navigation menu route definitions
const routes: Routes = [
	{
		path: ''
		, component: HomeComponent
		, pathMatch: 'full'
	}
	, {
		path: 'dashboard'
		, component: DashboardComponent
		, canActivate: [ AuthGuard ]
	}
	, {
		path: 'login'
		, component: LoginComponent
	}
	, {
		path: 'pfs-register'
		, component: PfsRegisterComponent
	}
	, {
		path: 'homer-register'
		, component: HomerRegisterComponent
	}
	, {
		path: 'help-ticket'
		, component: PublicHelpTicketComponent
	}
	, {
		path: 'support/tickets/feedback/:id/:guid'
		, component: HelpDeskTicketFeedbackComponent
	}
	, {
		path: 'heros-application'
		, component: HerosApplicationComponent
	}
	, {
		path: 'home-delivery'
		, component: HomeDeliveryComponent
	}
	, {
		path: 'support/web-release-notes'
		, component: WebReleaseNotesComponent
		, canActivate: [ AuthGuard ]
	}
	, {
		path: 'holidays'
		, component: HolidaysComponent
	}
	, {
		path: 'tech-spec'
		, component: TechSpecComponent
		, canActivate: [ AuthGuard ]
	}
	, {
		path: 'admin/users/user/edit/:id'
		, component: EditUserComponent
		, canActivate: [ AuthGuard ]
	}
	, {
		path: 'forgot-password'
		, component: ForgotPasswordComponent
	}
	, {
		path: 'change-password/:id'
		, component: ChangePasswordComponent
	}
	// IMPORTANT: This will be the path for ur CI Invoice email, accessible by the public API.
	, {
		path: 'public/invoice/pdf/:code'
		, component: CiInvoicePdfComponent
	}
	, {
		path: 'admin/system/quartz/pcg-history'
		, component: SilkierHistoryComponent
		, canActivate: [ AuthGuard ]
	}
	, {
		path: 'ci-short-over'
		, component: CiRepackOrderShortOverPrintableComponent
	}
	, {
		path: 'support/knowledge-base'
		, canActivate: [ AuthGuard ]
		, component: KnowledgeBaseComponent
		, data: {
			moduleAccess: [
				{
					modules: SecurityService.anyArea()
					, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
				}
			]
		}
	}
	, {
		path: 'repositoryregister'
		, component: RepositoryRegisterComponent
	}
	, {
		path: 'donorregistration'
		, component: DonorRegistrationComponent
	}
	, {
		path: 'patient-application'
		, component: PatientApplicationComponent
	}
	, {
		path: 'patient-application/:id'
		, component: PatientApplicationComponent
		, data: {
			moduleAccess: [
				{
					modules: [ BusinessAreaEnum.RxConnects ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
				}
			]
		}
	}
	, {
		path: 'support'
		, canLoad: [ AuthGuard ]
		, loadChildren: () => import('../help-desk/help-desk.module').then(m => m.HelpDeskModule)
	},
	, {
		path: 'error-report'
		, canLoad: [ AuthGuard ]
		, loadChildren: () => import('../error-report/error-report.module').then(m => m.ErrorReportModule)
	}
	, {
		path: 'admin'
		, canLoad: [ AuthGuard ]
		, loadChildren: () => import('../administration/administration.module').then(m => m.AdministrationModule)
		, data: {
			moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Admin ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
				}
			]
		}
	}
	, {
		path: 'pcg'
		, canLoad: [ AuthGuard ]
		, loadChildren: () => import('../administration/pcg.module').then(m => m.PcgModule)
		, data: {
			moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Admin ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
				}
			]
		}
	}
	, {
		path: 'heros'
		, canLoad: [ AuthGuard ]
		, data: {
			moduleAccess: [
				{
					modules: [ BusinessAreaEnum.HEROS ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
				}
			]
		}
		, loadChildren: () => import('../heros/heros.module').then(m => m.HEROSModule)
	}	
	, {
		path: 'ndcs'
		, canLoad: [ AuthGuard ]
		, data: {
			moduleAccess: [
				{
					modules: [ BusinessAreaEnum.NDC ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
				}
			]
		}
		, loadChildren: () => import('../redbook/redbook.module').then(m => m.RedbookModule)
	}
	, {
		path: 'ndcs'
		, canActivate: [ AuthGuard ]
		, data: {
			moduleAccess: [
				{
					modules: [ BusinessAreaEnum.NDC ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
				}
			]
		}
		, loadChildren: () => import('../ndc-directory/ndc-directory.module').then(m => m.NdcDirectoryModule)
	}
	, {
		path: 'pharmacy'
		, canLoad: [ AuthGuard ]
		, loadChildren: () => import('../reports/pharmacy/reportsPharmacy.module').then(m => m.ReportsPharmacyModule)
	}
	, {
		path: 'pharmacy'
		, canLoad: [ AuthGuard ]
		, data: {
			moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Inventory ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
				}
			]
		}
		, loadChildren: () => import('../pharm-log/pharm-log.module').then(m => m.PharmLogModule)
	}
	, {
		path: 'pharmacy'
		, canActivate: [ AuthGuard ]
		, data: {
			moduleAccess: [
				{
					modules: [ BusinessAreaEnum.Inventory ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.Technician)
				}
			]
		}
		, loadChildren: () => import('../fulfillment/fulfillment.module').then(m => m.FulfillmentModule)
	}
	, {
		path: 'pharmacy'
		, canLoad: [ AuthGuard ]
		, data: {
			moduleAccess: [
				{
					modules: [ BusinessAreaEnum.CI ]
					, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
				}
			]
		}
		, loadChildren: () => import('../inventory/inventory.module').then(m => m.InventoryModule)
	}	
	, {
		path: 'reporting'
		, canLoad: [ AuthGuard ]
		, loadChildren: () => import('../reports/reporting.module').then(m => m.ReportingModule)
	}
	, {
		path: 'metabolic-formula'
		, canLoad: [ AuthGuard ]
		, loadChildren: () => import('../metabolic-formula/metabolic-formula.module').then(m => m.MetabolicFormulaModule)
	}
	, {
		path: 'ci-repack'
		, canLoad: [ AuthGuard ]
		, loadChildren: () => import('../ci-repack/ci-repack.module').then(m => m.CiRepackModule)
	}
	, {
		path: 'icq'
		, canLoad: [ AuthGuard ]
		, loadChildren: () => import('../icq/icq.module').then(m => m.IcqModule)
	}
	, {
		path: 'shared'
		, canActivate: [ AuthGuard ]
		, canLoad: [ AuthGuard ]
		, loadChildren: () => import('../shared/shared.module').then(m => m.SharedModule)
	}
	, {
		path: 'rxconnects'
		, loadChildren: () => import('../rxconnects/rxconnects.module').then(m => m.RxConnectsModule)
	}
	, {
		path: 'inventories'
		, loadChildren: () => import("../shared/facilities/facility-inventories/inventories.module").then(m => m.InventoriesModule)
	}
	, {
		path: 'inv'
		, loadChildren: () => import("../formulary-inventory/formulary-inventory.module").then(m => m.FormularyInventoryModule)
	}
	, {
		path: 'ba'
		, loadChildren: () => import("../ba/ba.module").then(m => m.BAModule)
	}
	, {
		path: 'icq/scheduling/:icqReviewId/:userId'
		, component: IcqSchedulingComponent
	}
    , {
        path: 'icq-feedback/:id/:guid'
        , component: IcqFeedbackComponent
    }
	, {
		path: '**'
		, redirectTo: ''
	}
];

export class CoreRouting {
	static declarations = [
		HomeComponent
		, DashboardComponent
		, LoginComponent
		, HolidaysComponent
		, Error404Component
		, ForgotPasswordComponent
		, ChangePasswordComponent
		, KnowledgeBaseComponent
		, SilkierHistoryComponent
		, PfsRegisterComponent
		, HomerRegisterComponent
		, PublicHelpTicketComponent
		, RepositoryRegisterComponent
		, CiInvoicePdfComponent
		, CiRepackOrderShortOverPrintableComponent
	];
	static imports = [
		RouterModule.forRoot(
			routes
			, {
				scrollPositionRestoration: 'enabled'
				, preloadingStrategy: QuicklinkStrategy
				, anchorScrolling: 'enabled'
			}
		)
		, EditUserModule
	];
	static exports = [ RouterModule ];
	static providers = [ AuthGuard ];
}