import { ErrorHandler, Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';

import { pcgSettings }  from './app/shared/generated/pcg-settings';

@Injectable()
export class DatadogErrorHandler implements ErrorHandler {

    constructor() { }

    // The following are error codes thrown as a result of
	// system security and should not be logged to datadog.
	// If you make changes here, also update the ErrorLoggingMiddleware.cs
	ignoredErrorCodes: number[] = [
		401   // Login redirect
		, 403 // Access denied
		, 466 // Notification.Confirm() Msg
		, 467 // 2fa not enabled
	];

    handleError(err: any) {
        if (pcgSettings.mode.toLowerCase() === 'development') { console?.error(err?.originalError || err); }
        else if (!this.ignoredErrorCodes.includes(err.status)) { datadogLogs.logger.error(err?.message, undefined, err); }
    }
}
