import { Component, OnInit, computed, signal, ViewChild, ElementRef, inject, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheckDouble, faTrash, faGear } from '@fortawesome/pro-solid-svg-icons';
import { toSignal } from '@angular/core/rxjs-interop';
import { UserNotificationVm } from 'app/shared/generated/Models/Notifications/UserNotificationVm';
import { NotificationsService } from './notifications.service';
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { SecurityService } from 'app/core/security/security.service';
import { SharedModule } from 'app/shared/shared.module';
import { NavigationService } from 'app/shared/navigation/navigation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'pcg-dashboard-notifications-widget',
  standalone: true,
  imports: [CommonModule, FormsModule, NgbModule, NgbDropdown, NgbDropdownToggle, 
    NgbDropdownMenu, NgbTooltip, FontAwesomeModule, SharedModule],
  templateUrl: './notifications-widget.component.html',
  styleUrls: ['./notifications-widget.component.scss']
})
export class DashboardNotificationsWidgetComponent implements OnInit {
  @ViewChild('notificationsList') notificationsList!: ElementRef;
  notificationsService = inject(NotificationsService);
  sec = inject(SecurityService);
  navService = inject(NavigationService);
  router = inject(Router);
  
  showUnreadOnly = signal(true);
  displayLimit = signal(5);
  isScrolledToBottom = signal(false);
  user: UserJsVm;
  
  faCheckDouble = faCheckDouble;
  faTrash = faTrash;
  faGear = faGear;

  private notifications = toSignal(
    this.notificationsService.notifications$,
    { initialValue: [] as UserNotificationVm[] }
  );

  filteredNotifications = computed(() => {
    const notifications = this.notifications();
    const filtered = this.showUnreadOnly()
      ? notifications.filter(n => !n.markedAsRead)
      : notifications;
    return filtered.slice(0, this.displayLimit());
  });

  hasMoreNotifications = computed(() => {
    const total = this.showUnreadOnly()
      ? this.notifications().filter(n => !n.markedAsRead).length
      : this.notifications().length;
    return total > this.displayLimit();
  });

  ngOnInit(): void {
    this.user = this.sec.getUser();
    this.loadNotifications();
  }

  loadNotifications(): void {
    this.notificationsService.getNotificationsByUserId(this.user?.id);
  }

  markAsRead(notification: UserNotificationVm): void {
    this.notificationsService.markAsRead(notification.userNotificationId);
  }

  deleteNotification(notification: UserNotificationVm): void {
    this.notificationsService.deleteNotification(notification.userNotificationId);
  }

  deleteAllNotifications(): void {
    this.notificationsService.deleteAllNotifications(this.user?.id);
  }

  markAllAsRead(): void {
    this.notificationsService.markAllAsRead(this.user?.id);
  }

  setUnreadFilter(showUnreadOnly: boolean): void {
    this.showUnreadOnly.set(showUnreadOnly);
  }

  showMore(): void {
    this.displayLimit.update(current => current + 5);
  }

  onScroll(event: Event): void {
    const element = event.target as HTMLElement;
    const atBottom = Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 1;
    this.isScrolledToBottom.set(atBottom);

    // Auto-load more on mobile when near bottom
    if (this.navService.isMobile() && element.scrollTop + element.clientHeight >= element.scrollHeight - 100) {
      if (this.hasMoreNotifications()) {
        this.showMore();
      }
    }
  }

  navToSettings() {
    this.router.navigate([`/admin/users/user/edit/${this.sec.getUser()?.id}`], { queryParams: { tab: 'Notifications' } });
  }
}