import { computed, inject, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { toObservable } from '@angular/core/rxjs-interop';
import { UserNotificationVm } from 'app/shared/generated/Models/Notifications/UserNotificationVm';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { SystemMessage } from 'app/core/system-message/system-message-service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    public notifications = signal<UserNotificationVm[]>([]);
    readonly notifications$ = toObservable(this.notifications);
    http = inject(HttpClient);
    toastr = inject(ToastrService);

    unreadCount = computed(() => {
        return this.notifications().filter(n => !n.markedAsRead).length;
      });

    getNotificationsByUserId(userId: number): void {
        this.http.get<UserNotificationVm[]>(`api/dashboard/notifications/${userId}`)
            .subscribe({
                next: (notifications) => {
                    this.notifications.set(
                        notifications.sort((a, b) => new Date(b.notifiedDate).getTime() - new Date(a.notifiedDate).getTime())
                    );
                },
                error: (error) => {
                    this.toastr.error('Error fetching notifications', null, {
                        positionClass: "toast-top-right",
                        timeOut: 2000,
                    });
                    console.error('Error fetching notifications:', error);
                }
            });
    }

    markAsRead(notificationId: number): void {
        this.http.post<SystemMessage>(`api/dashboard/notifications/${notificationId}/mark-read`, {})
            .pipe(
                tap(() => {
                    this.notifications.update(notifications =>
                        notifications.map(n =>
                            n.userNotificationId === notificationId
                                ? { ...n, markedAsRead: true, markedAsReadDate: new Date() }
                                : n
                        )
                    );
                }),
                catchError(error => {
                    this.toastr.error('Error marking notification as read', null, {
                        positionClass: "toast-top-right",
                        timeOut: 2000,
                    });
                    console.error('Error marking notification as read:', error);
                    return of(false);
                })
            )
            .subscribe();
    }

    markAllAsRead(userId: number): void {
        this.http.post<SystemMessage>(`api/dashboard/notifications/mark-all-read/${userId}`, {})
            .pipe(
                tap(() => {
                    this.notifications.update(notifications =>
                        notifications.map(n => ({
                            ...n,
                            markedAsRead: true,
                            markedAsReadDate: new Date()
                        }))
                    );
                }),
                catchError(error => {
                    this.toastr.error('Error marking all notifications as read', null, {
                        positionClass: "toast-top-right",
                        timeOut: 2000,
                    });
                    console.error('Error marking all notifications as read:', error);
                    return of(false);
                })
            )
            .subscribe();
    }

    deleteNotification(notificationId: number): void {
        this.http.delete<SystemMessage>(`api/dashboard/notifications/${notificationId}`)
            .pipe(
                tap(() => {
                    this.notifications.update(notifications =>
                        notifications.filter(n => n.userNotificationId !== notificationId)
                    );
                }),
                catchError(error => {
                    this.toastr.error('Error deleting notification', null, {
                        positionClass: "toast-top-right",
                        timeOut: 2000,
                    })
                    console.error('Error deleting notification:', error);
                    return of(false);
                })
            )
            .subscribe();
    }

    deleteAllNotifications(userId: number): void {
        this.http.delete<SystemMessage>(`api/dashboard/notifications/delete-all/${userId}`)
            .pipe(
                tap(() => {
                    this.notifications.set([]);
                }),
                catchError(error => {
                    this.toastr.error('Error deleting all notifications', null, {
                        positionClass: "toast-top-right",
                        timeOut: 2000,
                    });
                    console.error('Error deleting all notifications:', error);
                    return of(false);
                })
            )
            .subscribe();
    }
}