import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SseNotificationComponent } from './sse-notification.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
@NgModule({
	declarations: [
        SseNotificationComponent
	]
	, imports: [
		CommonModule,
        FontAwesomeModule
	]
	, exports: [
        SseNotificationComponent
	]
})
export class SseNotificationModule { }
