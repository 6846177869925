import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { QuicklinkModule } from 'ngx-quicklink';
import { ToastrModule } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { DatadogErrorHandler } from 'datadog_config';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { DashboardNotificationsWidgetComponent } from "./core/pages/dashboard/widgets/notifications-widget/notifications-widget.component";

/**
 * This is the first module loaded. It bootstraps
 * the rest of the application.
 */
@NgModule({
	declarations: [ AppComponent ]
	, bootstrap: [ AppComponent ]
	, imports: [
        BrowserModule
        , CoreModule
        , QuicklinkModule
        , ToastrModule.forRoot({ positionClass: 'toast-top-center' })
        , BrowserAnimationsModule
        , ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: true
            , registrationStrategy: 'registerImmediately'
        })
        , DashboardNotificationsWidgetComponent
        , MatProgressSpinnerModule
    ]
	, providers: [
		{ 
			provide: ErrorHandler
			, useClass: DatadogErrorHandler
		}
        , {
            provide: SwRegistrationOptions
            , useFactory: () => ({ enabled: true })
        }
	]	
})
export class AppModule { }