import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { SecurityService } from '../security/security.service';
import { SseNotificationDataVm } from 'app/shared/generated/Models/SseNotificationDataVm';
import { FaBusinessAreaIconsService } from 'app/shared/fa-business-area-icons/fa-business-area-icons.service';
import { FaIconName } from 'app/shared/fa-num-icon/fa-icon-name/fa-icon-name';
import { faExclamation, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { notificationSlide } from 'app/shared/animations/shared-animations';
import { SseNotificationService } from './sse-notification.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { pcgSettings } from 'app/shared/generated/pcg-settings';

@Component({
    selector: 'pcg-sse-notification',
    templateUrl: './sse-notification.component.html',
    styleUrls: ['./sse-notification.component.scss'],
    standalone: false,
    animations: [notificationSlide]
})
export class SseNotificationComponent implements OnInit, OnDestroy {
    sseService = inject(SseNotificationService);
    sec = inject(SecurityService);
    businessIcons = inject(FaBusinessAreaIconsService);
    router = inject(Router);
    settings = pcgSettings;

    subscriptions: Subscription[] = [];
    /*
        If you want to add custom icons to your notification 
        and not use the default business area ones
        add the icon to the FaIconName object
    */
    icons = new FaIconName();
    // If an icon is not found/set just default to '!' icon
    defaultIcon = faExclamation;
    dismissIcon = faAngleRight;

    notifs: SseNotificationDataVm[] = [];

    sound = new Audio();

    // Timeout and highlight logic
    timeoutId: any;
    startTime;
    widthIntervalId: any;
    widthInterval = 100;
    barWidth = 0;

    // Max size of notif array
    MAX_NOTIF = 5;
    // How much to offset older notifications in stack
    NOTIF_OFFSET = 15;
    // Opacity for stacks notifs
    OLD_NOTIF_OPACITY = 0.2;
    // Converting milliseconds to seconds for timeout
    MS_TO_S = 1000;
    // Ensure notifications are always on top
    NOTIF_Z_INDEX = 9999;

    // Defaults
    DEFAULT_POSITION = ["bottom", "right"];
    DEFAULT_HEADER_COLOR = "#FFFFFF";
    DEFAULT_MESSAGE_COLOR = "#FFFFFF";

    get direction(): string {
        return this.notifs[this.notifs?.length - 1]?.position?.includes('left') ? '-100%' : '100%';
    }

    ngOnInit() {
        // Until tested more, disable SSE notifications on prod
        if(this.settings.mode.toLowerCase() === "production") return;

        // Ensure no existing SSE already setup
        this.sseService.destroySSE();

        // Whenever service says there is a notif fire, keep track to close subscribe on destroy
        this.subscriptions.push(
            this.sseService.notificationReceived.subscribe((data: SseNotificationDataVm) => {
                this.addNotifToQueue(data);
                this.setDefaults();
                this.setIcon();
                this.playSound();
                this.startTimer();
            })
        );

        // Initial setup (typically hit if already logged in)
        this.sseService.initializeSSE();   
    }

    ngOnDestroy() {
        if (this.sseService.notificationSource) {
            this.sseService.destroySSE();
        }
        this.stopTimer();
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    //#region New notif logic
    addNotifToQueue(data: SseNotificationDataVm) {
        data.timeSent = new Date();
        this.notifs?.length > 0 ?
            this.notifs.push(data) :
            this.notifs = [data];
        if (this.notifs?.length >= this.MAX_NOTIF) {
            this.notifs = this.notifs.slice(-this.MAX_NOTIF);
        }
    }

    setDefaults() {
        let notif = this.notifs[this.notifs.length - 1];
        if (!notif.position || notif.position.length == 0)
            notif.position = this.DEFAULT_POSITION;
        if (!notif.headerColor) notif.headerColor = this.DEFAULT_HEADER_COLOR;
        if (!notif.messageColor) notif.messageColor = this.DEFAULT_MESSAGE_COLOR;
    }

    setIcon() {
        let notif = this.notifs[this.notifs.length - 1];
        if (notif.businessArea && !notif.icon) {
            notif.iconDefinition = this.businessIcons.getIconByBusinessArea(notif.businessArea);
        } else if (notif.icon) {
            notif.iconDefinition = this.icons[notif.icon];
        }

        let iconStyle = this.businessIcons.getIconStyleByBusinessArea(notif.businessArea);
        if (!notif.iconColor) notif.iconColor = iconStyle.color;
        if (!notif.iconBgColor) notif.iconBgColor = iconStyle.background;
    }
    //#endregion
    
    //#region Sound Logic
    playSound() {
        let notif = this.notifs[this.notifs.length - 1];
        if(notif.useSound) {
            if(!this.sound.src) { this.loadNotifSound(); }
            this.sound.play();
        }
    }

    private loadNotifSound() {
        const basePath = document.getElementsByTagName('base')[0].href;
        this.sound.src = basePath + 'assets/sounds/notif-ding.mp3';
        this.sound.load();
    }
    //#endregion

    //#region Click logic
    dismissNotif() {
        this.notifs.pop();
        this.stopTimer();
        this.startTimer();
    }

    bringToFront(index: number) {
        // Index comes in from reversed array.
        // Use Length - index - 1 to get actual index of original array
        let realIndex = this.notifs?.length - index - 1; 
        const [element] = this.notifs.splice(realIndex, 1); 
        this.notifs.push(element);
        this.startTimer();
    }

    urlRedirect() {
        let notif = this.notifs[this.notifs.length - 1];
        (notif.openNewTab && notif.url) ? window.open(notif.url, '_blank') : this.router.navigateByUrl(notif.url);
        this.dismissNotif();
    }
    //#endregion
     
    //#region Timeout logic
    startTimer() {
        this.stopTimer();
        if (this.notifs[this.notifs?.length - 1]?.timeout) {
            this.startTime = new Date();
            this.setTimeoutWidth();
            this.timeoutId = setTimeout(() => {
                this.dismissNotif();
            }, this.notifs[this.notifs?.length - 1].timeout * this.MS_TO_S);
        }
    }

    stopTimer() {
        if (this.timeoutId) {
            clearInterval(this.timeoutId);
            this.timeoutId = null;
            this.startTime = null;
        }
        if (this.widthInterval) {
            clearInterval(this.widthIntervalId);
            this.widthIntervalId = null;
        }

        // Reset timeout-bar back to 0, this prevents notifications without timeout
        // from having a random hightlight bar
        this.barWidth = 0;
    }

    setTimeoutWidth() {
        let notif = this.notifs[this.notifs.length - 1];
        this.widthIntervalId = setInterval(() => {
            const elapsed = (new Date().getTime() - this.startTime.getTime()) / 1000;
            const remaining = Math.max(notif.timeout - elapsed, 0);
            this.barWidth = (remaining / notif.timeout) * 100;
            if(this.barWidth == 0) 
                clearInterval(this.widthIntervalId);
        }, this.widthInterval);
    }
    //#endregion
}
